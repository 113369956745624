/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat, ITrafficLight } from '../../../models/chat/chat';

interface LiveChatSliceInterface {
  chatsOnConversation: Chat[];
  chatIdFrom: string;
}

const initialState: LiveChatSliceInterface = {
  chatsOnConversation: [],
  chatIdFrom: '',
};

export const chatsOnConversationToState = createSlice({
  name: 'chatsOnConversationToState',
  initialState,
  reducers: {
    setChatsOnConversation: (
      state: { chatsOnConversation: Chat[] },
      action: PayloadAction<Chat[]>,
    ) => {
      state.chatsOnConversation = action.payload;
    },

    setOneChatOnConversation: (
      state: { chatsOnConversation: Chat[] },
      action: PayloadAction<Chat>,
    ) => {
      if (
        state?.chatsOnConversation?.find(
          (chat) => chat._id === action.payload._id,
        )
      ) {
        state.chatsOnConversation = state.chatsOnConversation.filter(
          (chat) => chat._id !== action.payload._id,
        );
        state.chatsOnConversation = [
          (state.chatsOnConversation[
            state.chatsOnConversation?.findIndex(
              (chat) => chat._id === action.payload._id,
            )
          ] = action.payload),
          ...state.chatsOnConversation,
        ];
      } else {
        state.chatsOnConversation = [
          action.payload,
          ...state.chatsOnConversation,
        ];
      }
    },

    removeOneChatFromOnConversation: (
      state: { chatsOnConversation: Chat[] },
      action: PayloadAction<string>,
    ) => {
      state.chatsOnConversation = state.chatsOnConversation.filter(
        (chat) => chat._id !== action.payload,
      );
    },

    setChatsTrafficLight: (
      state: { chatsOnConversation: Chat[] },
      action: PayloadAction<{ chatId: string; trafficLight: ITrafficLight }[]>,
    ) => {
      action.payload.forEach((chatTrafficLight) => {
        state.chatsOnConversation = state.chatsOnConversation.map((chat) => {
          if (chat._id === chatTrafficLight.chatId) {
            chat.trafficLight = chatTrafficLight.trafficLight;
          }
          return chat;
        });
      });
    },

    setUnreadedChatsOnConversation: (
      state: { chatsOnConversation: Chat[] },
      action: PayloadAction<Chat>,
    ) => {
      state.chatsOnConversation = state.chatsOnConversation.filter(
        (chat) => chat._id !== action.payload._id,
      );
      state.chatsOnConversation.forEach((chat) => {
        chat.selected = false;
      });
      state.chatsOnConversation = [
        (state.chatsOnConversation[
          state.chatsOnConversation?.findIndex(
            (chat) => chat._id === action.payload._id,
          )
        ] = action.payload),
        ...state.chatsOnConversation,
      ];
    },

    setChatIdFrom: (
      state: { chatIdFrom: string },
      action: PayloadAction<string>,
    ) => {
      state.chatIdFrom = action.payload;
    },
  },
});

export const {
  setChatsOnConversation,
  setOneChatOnConversation,
  removeOneChatFromOnConversation,
  setChatsTrafficLight,
  setUnreadedChatsOnConversation,
  setChatIdFrom,
} = chatsOnConversationToState.actions;
export default chatsOnConversationToState.reducer;
