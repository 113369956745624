import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { authApi } from '../../api/base';
import { appLogger } from '../../helpers/logger';
import useLocalStorage from '../use-local-storage';
import { useToastContext } from '../../components/shared/molecules/Toast/useToast';
import { Toast } from '../../components/shared/molecules/Toast/Toast.interface';
// import { setUserDataInState } from '../../redux/slices/auth/user-credentials';
// import { useAppDispatch } from '../../redux/hook/hooks';
// import { DecodedToken } from '../../models/users/user';
import { websocketContext } from '../../chat';

export const useAuth = () => {
  const toasts = useToastContext();

  const { push, reload } = useRouter();
  // const dispatch = useAppDispatch();
  const socket: any = useContext(websocketContext);

  const [accessToken, setAccessToken] = useLocalStorage('AccessToken', '');

  useEffect(() => {
    appLogger.info({ accessToken });
  }, [accessToken]);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await authApi.post<{
        accessToken: string;
      }>('/signin', {
        email,
        password,
      });
      if (response.errorMessage === 'User not found') {
        toasts?.addToast({
          alert: Toast.ERROR,
          title: 'Error!',
          message: `El usuario no se encuentra registrado.`,
        });
      } else if (response.errorMessage === 'Wrong email or password') {
        toasts?.addToast({
          alert: Toast.ERROR,
          title: 'Error!',
          message: `El usuario o constraseña ingresados son incorrectos.`,
        });
      } else {
        setAccessToken(response.accessToken);
        reload();
      }
      // toasts?.addToast({
      //   alert: Toast.SUCCESS,
      //   title: 'Bienvenido !',
      //   message: 'Te has logueado correctamente',
      // });
    } catch (error) {
      toasts?.addToast({
        alert: Toast.ERROR,
        title: 'Error !',
        message:
          'Compruebe que el nombre de usuario y la contraseña sean correctos',
      });
      localStorage.removeItem('AccessToken');
    }
  };

  const signOut = async () => {
    try {
      await authApi.post<{
        accessToken: string;
      }>('/signout', {
        accessToken,
        // refresh token
      });

      // dispatch(setUserDataInState({} as DecodedToken));
      socket?.emit('logout');
      window.localStorage.removeItem('AccessToken');
      push('/');
    } catch (error) {
      appLogger.error(error);
    }
  };

  return {
    signIn,
    signOut,

    // renewToken,
  };
};
