/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chat, IPageChat } from '../../../models/chat/chat';

export interface LiveChatSliceInterface {
  chatsPendings: Chat[];
  allChatsPage: IPageChat[];
  chatsByPage: IPageChat;
  loading: boolean;
}

const initialState: LiveChatSliceInterface = {
  chatsPendings: [],
  allChatsPage: [],
  chatsByPage: {} as IPageChat,
  loading: false,
};

export const chatsPendingsToState = createSlice({
  name: 'chatsPendingsToState',
  initialState,
  reducers: {
    setChatsPendings: (
      state: { chatsPendings: Chat[] },
      action: PayloadAction<Chat[]>,
    ) => {
      state.chatsPendings = action.payload;
    },
    setOneChatPending: (
      state: { chatsPendings: Chat[] },
      action: PayloadAction<Chat>,
    ) => {
      if (
        state?.chatsPendings?.find((chat) => chat._id === action.payload._id)
      ) {
        state.chatsPendings = state.chatsPendings.filter(
          (chat) => chat._id !== action.payload._id,
        );
        state.chatsPendings = [
          (state.chatsPendings[
            state.chatsPendings?.findIndex(
              (chat) => chat._id === action.payload._id,
            )
          ] = action.payload),
          ...state.chatsPendings,
        ];
      } else {
        state.chatsPendings = [action.payload, ...state.chatsPendings];
      }
    },
    setloading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getChatByPage: (state, action: PayloadAction<number>) => {
      const searchPage = state.allChatsPage?.find(
        (elem) => elem.page === action.payload,
      );
      if (searchPage) {
        state.chatsByPage = searchPage;
      }
    },
    setChatsPage: (state) => {
      state.allChatsPage = [];
      const numPage = 8;
      const totalPage =
        state.chatsPendings && Math.ceil(state.chatsPendings.length / numPage);
      if (totalPage === 1 || totalPage === 0) {
        state.allChatsPage = [
          {
            chats: [...state.chatsPendings],
            page: 1,
          },
        ];
      }
      if (totalPage > 1) {
        const arrayPage = new Array(totalPage).fill(0).map((_val, i) => i + 1);
        if (state.allChatsPage.length < 1) {
          arrayPage.forEach((item) => {
            const resp = state.chatsPendings.slice(
              (item - 1) * numPage,
              (item - 1) * numPage + numPage,
            );
            state.allChatsPage = [
              {
                chats: resp,
                page: item,
              },
              ...state.allChatsPage,
            ];
          });
        }
      }
    },
    removeOneChatPending: (state, action: PayloadAction<string>) => {
      state.chatsPendings = state.chatsPendings.filter(
        (chat) => chat._id !== action.payload,
      );
    },
  },
});

export const {
  setChatsPendings,
  setOneChatPending,
  removeOneChatPending,
  setChatsPage,
  setloading,
  // setOneChatPage,
  getChatByPage,
} = chatsPendingsToState.actions;
export default chatsPendingsToState.reducer;
